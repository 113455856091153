import { $config, $waitForClient } from '@client/core/atoms/config.js';
import {
  refreshKeywords,
  type FilterUpdatePayloadType
} from '@client/core/utils/refreshKeywords.js';
import { events, messageBus } from '@schibsted-nmp/advertising-events';
import { debugLog } from '@schibsted-nmp/advertising-shared';

type TravelPageLoadedEvent = {
  payload: {
    pageType: string;
    page?: number;
    destination?: {
      continent?: string;
      country?: string;
      city?: string;
    };
    destinasjon?: {
      continent?: string;
      country?: string;
      city?: string;
    };
    subvertical: string;
  };
};

export function setupTravelEvents() {
  const { pageType } = $config.get();

  if (pageType === 'result') {
    messageBus.subscribe<FilterUpdatePayloadType>(
      events.SEARCH.channel,
      events.SEARCH.FILTER.topic,
      (event) => {
        refreshKeywords({
          ...event,
          payload: { searchParams: event.payload }
        });
      }
    );
  }

  // Used by the travel vertial to set keywords on page load because they're not getting all the proper pageOpts
  const peekEvent = messageBus.peek('page', 'loaded') as TravelPageLoadedEvent;
  if (peekEvent) {
    travelLoadedCallback(peekEvent);
  } else {
    messageBus.subscribe('page', 'loaded', (e) => {
      travelLoadedCallback(e as TravelPageLoadedEvent);
    });
  }
}

const travelLoadedCallback = (event: TravelPageLoadedEvent) => {
  $waitForClient.set(true);

  debugLog('Travel page loaded event received', event);

  refreshKeywords({
    ...event,
    payload: { searchParams: event.payload }
  }).then(() => {
    $waitForClient.set(false);
  });
};
