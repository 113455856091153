import { html } from 'lit';

import { AdVendor } from '@client/core/AdVendor.js';
import { AdServer, PlacementId } from '@schibsted-nmp/advertising-shared';
import { renderAdnAd } from '@client/adnuntius/AdUnit/renderAd.js';

export class AdnAdVendor extends AdVendor {
  constructor(placementId: PlacementId) {
    super(AdServer.ADN, placementId, `${placementId}--container`);
  }

  override updated() {
    // do nothing
  }

  override cleanupEvents() {
    // do nothing
  }

  override setupEvents() {
    // do nothing
  }

  override requestAd(): void {
    renderAdnAd({
      placementId: this.placementId,
      containerId: this.containerId || `${this.placementId}--container`
    });
  }

  override render() {
    return html`
      <slot
        name="${`${this.placementId}--slot`}"
        id="${this.placementId}"
      ></slot>
    `;
  }
}
