import type {
  EntryValue,
  GeneratorYieldValue
} from '@schibsted-nmp/advertising-shared';

import { CustomSettings } from './CustomSettings.js';
import type { Pbjs } from '../Pbjs.js';

export namespace ConsentManagement.CustomVendors {
  export function* generate<TMap extends CustomSettings.Map>(
    bidderCodeToCustomSettingsMap: TMap
  ) {
    for (const [
      bidderCode,
      { schainNodes, consentExcepted = null }
    ] of bidderCodeToCustomSettingsMap) {
      if (!schainNodes) continue;
      yield [bidderCode, { schainNodes, consentExcepted }] as const;
    }
  }

  export function from<TMap extends CustomSettings.Map>(
    bidderCodeToCustomSettingsMap: TMap
  ): Map {
    return new Map(generate(bidderCodeToCustomSettingsMap));
  }

  export type Value = EntryValue<
    GeneratorYieldValue<ReturnType<typeof generate>>
  >;

  export type Map<T extends Value = Value> = CustomSettings.Map<T>;
}

export namespace ConsentManagement.Rules {
  export function* generate(
    customConsentVendorsMap: ConsentManagement.CustomVendors.Map
  ) {
    const defaultVendorExceptions = [
      ...Exceptions.getDefaultVendors(customConsentVendorsMap)
    ] as const;

    for (const [
      purpose,
      vendorExceptions = defaultVendorExceptions
    ] of Exceptions.purposes) {
      yield {
        purpose,
        enforcePurpose: true,
        enforceVendor: true,
        vendorExceptions
      } as const satisfies Pbjs.Common.Config.ConsentManagement.Gdpr.Rule;
    }
  }

  export function from(
    customConsentVendorsMap: ConsentManagement.CustomVendors.Map
  ) {
    return Array.from(generate(customConsentVendorsMap));
  }

  export namespace Exceptions {
    export const purposes = [
      ['storage'],
      ['basicAds'],
      ['personalizedAds'],
      ['measurement', []],
      ['transmitPreciseGeo', []]
    ] as const satisfies ReadonlyArray<
      readonly [purpose: string, exceptions?: ReadonlyArray<string>]
    >;

    export function* getDefaultVendors(
      customConsentVendorsMap: ConsentManagement.CustomVendors.Map
    ) {
      for (const [bidderCode, { consentExcepted }] of customConsentVendorsMap) {
        if (consentExcepted) yield bidderCode;
      }
    }
  }
}
