import { $initialState } from '@client/core/atoms/config.js';

export default async function fetcher<T>(
  url: string,
  options?: RequestInit
): Promise<T> {
  const baseUrl = $initialState.get()?.baseUrl || '';
  const response = await fetch(`${baseUrl}${url}`, options);

  // Check if response has content before deserializing
  const contentType = response.headers.get('content-type');
  if (!contentType?.includes('application/json')) {
    // Return an empty object or whatever fits your response type
    return {} as T;
  }

  return response.json();
}
