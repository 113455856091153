import React from 'react';

import { createRoot } from 'react-dom/client';

import { updateMetricByKey } from '@client/core/atoms/metrics.js';
import { $placementsMap } from '@client/core/atoms/placements.js';
import { RenderSponsoredContentOrChildren } from '@client/sponsoredContent/RenderSponsoredContentOrChildren.js';
import {
  AdServer,
  type ClientAdPlacement,
  type GamPlacement,
  PlacementId,
  PlacementStatusesEnum
} from '@schibsted-nmp/advertising-shared';
import { getInViewSettingsByBrand } from '@client/core/utils/getInViewSettingsByPlacementId.js';

import GamAdUnit from './GamAdUnit.js';

export function renderGamAd(props: {
  placementId: PlacementId;
  containerId: string;
}) {
  const element = document.getElementById(props.containerId);
  const placementElement = element && createRoot(element);
  const inViewSettings = getInViewSettingsByBrand(props.placementId);

  if (placementElement) {
    const placements = $placementsMap.get();
    if (Object.keys(placements).length === 0) {
      console.error(`No placements found`);
      updateMetricByKey(props.placementId, PlacementStatusesEnum.NotInConfig);
      return;
    }
    const placement = placements[
      props.placementId
    ] as ClientAdPlacement<GamPlacement>;
    if (!placement) {
      console.error(`PlacementId: ${props.placementId} not found`);
      return;
    }
    const Children = (
      <GamAdUnit
        containerId={props.containerId}
        placement={placement}
        inViewSettings={inViewSettings}
      />
    );
    if (placement.overrideAdServer === AdServer.SponsoredContent) {
      placementElement.render(
        <RenderSponsoredContentOrChildren placement={placement} {...props}>
          {Children}
        </RenderSponsoredContentOrChildren>
      );
    } else {
      placementElement.render(Children);
    }
  }
}
