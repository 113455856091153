import { debugLog } from '@schibsted-nmp/advertising-shared';

import type { Api } from '../Api.js';
import type { PrebidRequester } from './PrebidRequester.js';
import { BidderSpecific } from './BidderSpecific.js';
import { Targeting } from './Targeting.js';
import { PrebidConfig } from './PrebidConfig.js';
import { CustomSettings } from './CustomSettings.js';
import { ConsentManagement } from './ConsentManagement.js';

export namespace GlobalJs {
  let executed = false;

  /**
   * Initializes Prebid through Relevant Digital. Acts as a replacement for globalJs
   * in the Relevant Yield backoffice, such that we can control it from the client.
   * Instead, only `window.__advt_ry_globaljs__.init(window.relevantDigital, PrebidRequester.instance)`
   * needs to be called.
   *
   * **NOTE:**
   * In order to leverage this properly, the Relevant Yield account needs to be
   * configured like the DK account. README will be updated with more information.
   */
  export function init(
    relevantDigital: Api.Loaded,
    prebidRequester: PrebidRequester
  ) {
    if (executed) return;
    executed = true;

    try {
      const {
        pbjs,
        siteData: { sspCustomSettings },
        byBidderInfo
      } = prebidRequester;

      const customSettingsMap = CustomSettings.from(
        sspCustomSettings,
        byBidderInfo
      );

      const customConsentVendorsMap =
        ConsentManagement.CustomVendors.from(customSettingsMap);

      BidderSpecific.Settings.apply(pbjs, customSettingsMap);
      BidderSpecific.Configs.apply(pbjs, customConsentVendorsMap);
      PrebidConfig.apply(relevantDigital, customConsentVendorsMap);
      Targeting.PageLevel.applyOnBeforeAdRequest(relevantDigital, pbjs);
    } catch (error) {
      debugLog(
        'Failed initiating Relevant Digital Prebid',
        prebidRequester,
        error
      );
    }
  }

  export type Window = {
    [Window.globalAccessor]?: Window.Api;
  };

  export namespace Window {
    export const globalAccessor = '__advt_ry_globaljs__' as const;

    export const Api = { init } as const;

    export type Api = typeof Api;

    export function inject() {
      if (typeof window === 'undefined') return;
      window[globalAccessor] ??= Api;
    }
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/consistent-type-definitions
  interface Window extends GlobalJs.Window {}
}
