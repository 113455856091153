import { $config } from '@client/core/atoms/config.js';
import {
  AdPlacement,
  type DeviceType,
  PlacementId
} from '@schibsted-nmp/advertising-shared';

/**
 * The threshold is a number between 0.0 and 1.0 inclusive, which indicates at what percentage of the target's
 * visibility the observer's callback should be executed. A threshold of 0.0 means that the callback will run
 * as soon as even one pixel is visible, and a threshold of 1.0 means that the callback will run when the entire target is visible.
 *
 * The rootMargin is a string which specifies a set of offsets to add to the root's bounding box when calculating intersections,
 * effectively shrinking or growing the area used for intersections. The syntax is similar to that of the CSS margin property,
 * and accepts values in pixels (px) or percentages (%). The values can be positive or negative, and they can be different for each side.
 * The order of the values is top, right, bottom, left.
 *
 * All placement will have different optimal settings.
 * Sometimes it might even differ per brand
 *
 * @param placementId
 */

const scrollMargins = {
  XXL: 800,
  XL: 600,
  LONG: 400,
  MEDIUM: 200,
  SHORT: 100,
  NONE: 0,
  DELAYED_SHORT: -100
};

const noThreshold = 0.0;

function getScrollMargin(length: number) {
  return `${length}px 0px ${length}px 0px`;
}

export const forceBatchRequestPlacements = [
  AdPlacement.Top1.id,
  AdPlacement.Left1.id,
  AdPlacement.Right1.id,
  AdPlacement.Wallpaper.id,
  AdPlacement.Audience.id
];

export type InViewSettings = {
  threshold: number;
  rootMargin: string;
} | null;

export function getInViewSettingsByBrand(
  placementId: PlacementId
): InViewSettings {
  const { brand, deviceType } = $config.get();

  switch (brand) {
    case 'finn': {
      return getInViewSettingsForFinnByPlacementId(placementId, deviceType);
    }
    case 'tori': {
      return getInViewSettingsForToriByPlacementId(placementId);
    }
    case 'dba': {
      return getInViewSettingsForDbaByPlacementId(placementId, deviceType);
    }
    default: {
      return null;
    }
  }
}

export function getInViewSettingsForDbaByPlacementId(
  placementId: PlacementId,
  deviceType: DeviceType
) {
  // search result placements
  if (placementId?.includes('middle_')) {
    return {
      threshold: noThreshold,
      rootMargin:
        deviceType === 'desktop'
          ? getScrollMargin(scrollMargins.SHORT)
          : getScrollMargin(scrollMargins.MEDIUM)
    };
  }

  // recommendation placements
  if (placementId?.includes('recirc')) {
    return {
      threshold: noThreshold,
      rootMargin:
        deviceType === 'desktop'
          ? getScrollMargin(scrollMargins.SHORT)
          : getScrollMargin(scrollMargins.MEDIUM)
    };
  }

  if (placementId === AdPlacement.Bottom1.id) {
    return {
      threshold: noThreshold,
      rootMargin: getScrollMargin(scrollMargins.XL)
    };
  }

  const isSidePlacement2 =
    placementId === AdPlacement.Right2.id ||
    placementId === AdPlacement.Left2.id;

  if (isSidePlacement2) {
    return {
      threshold: noThreshold,
      rootMargin:
        deviceType === 'desktop'
          ? getScrollMargin(scrollMargins.DELAYED_SHORT)
          : getScrollMargin(scrollMargins.NONE)
    };
  }

  // topbanner, skyscrapers etc
  return {
    threshold: noThreshold,
    rootMargin: getScrollMargin(scrollMargins.NONE)
  };
}

export function getInViewSettingsForFinnByPlacementId(
  placementId: PlacementId,
  deviceType: DeviceType
) {
  // search result placements
  if (placementId?.includes('middle_')) {
    return {
      threshold: noThreshold,
      rootMargin:
        deviceType === 'desktop'
          ? getScrollMargin(scrollMargins.SHORT)
          : getScrollMargin(scrollMargins.MEDIUM)
    };
  }

  // recommendation placements
  if (placementId?.includes('recirc')) {
    return {
      threshold: noThreshold,
      rootMargin: getScrollMargin(scrollMargins.SHORT)
    };
  }

  // topbanner, skyscrapers etc
  return {
    threshold: noThreshold,
    rootMargin: getScrollMargin(scrollMargins.NONE)
  };
}
export function getInViewSettingsForToriByPlacementId(
  placementId: PlacementId
) {
  switch (placementId) {
    case AdPlacement.Top2.id: {
      return {
        threshold: noThreshold,
        rootMargin: getScrollMargin(scrollMargins.NONE)
      };
    }
    case AdPlacement.Middle1.id: {
      return {
        threshold: noThreshold,
        rootMargin: getScrollMargin(scrollMargins.LONG)
      };
    }
    case AdPlacement.Middle2.id: {
      return {
        threshold: noThreshold,
        rootMargin: getScrollMargin(scrollMargins.LONG)
      };
    }
    case AdPlacement.RecircMiddle1.id: {
      return {
        threshold: noThreshold,
        rootMargin: getScrollMargin(scrollMargins.MEDIUM)
      };
    }
    case AdPlacement.RecircMiddle2.id: {
      return {
        threshold: noThreshold,
        rootMargin: getScrollMargin(scrollMargins.MEDIUM)
      };
    }
    case AdPlacement.Bottom1.id: {
      return {
        threshold: noThreshold,
        rootMargin: getScrollMargin(scrollMargins.XL)
      };
    }
    case AdPlacement.Left1.id:
    case AdPlacement.Right1.id:
    case AdPlacement.Wallpaper.id: {
      return null;
    }
    default: {
      return {
        threshold: noThreshold,
        rootMargin: getScrollMargin(scrollMargins.NONE)
      };
    }
  }
}
