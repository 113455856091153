/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ConfigRequest {
  brand?:
    | 'tori'
    | 'finn'
    | 'blocket'
    | 'dba'
    | 'bilbasen'
    | 'oikotie'
    | 'rakentaja';
  device?: 'desktop' | 'mobile' | 'android' | 'ios';
  vertical?:
    | 'recommerce'
    | 'mobility'
    | 'jobs'
    | 'realestate'
    | 'travel'
    | 'front';
  subVertical?:
    | 'industry'
    | 'cars'
    | 'boats'
    | 'motorcycles'
    | 'caravans'
    | 'residential-forsale'
    | 'residential-forrent'
    | 'leisure-forsale'
    | 'business-forsale'
    | 'business-forrent'
    | 'holiday-homes'
    | 'hotels'
    | 'flights'
    | 'prepackages'
    | null;
  page?: 'front' | 'result' | 'object';
  searchKey?:
    | 'SEARCH_ID_JOB_MANAGEMENT'
    | 'SEARCH_ID_REALESTATE_LETTINGS'
    | 'SEARCH_ID_REALESTATE_HOMES'
    | 'SEARCH_ID_BOAT_PARTS'
    | 'SEARCH_ID_BOAT_RENT'
    | 'SEARCH_ID_BOAT_RENT_WANTED'
    | 'SEARCH_ID_AGRICULTURE_TOOL'
    | 'SEARCH_ID_BAP_WEBSTORE_SHOP'
    | 'SEARCH_ID_BAP_ALL'
    | 'SEARCH_ID_REALESTATE_ABROAD_HOMES'
    | 'SEARCH_ID_BAP_FREE'
    | 'SEARCH_ID_MC_ATV'
    | 'SEARCH_ID_TRAVEL_HOTEL'
    | 'SEARCH_ID_BAP_WANTED'
    | 'SEARCH_ID_CAR_USED'
    | 'SEARCH_ID_COMPANY_SALE'
    | 'SEARCH_ID_BOAT_MOTOR'
    | 'SEARCH_ID_BOAT_NEW'
    | 'SEARCH_ID_BOAT_COMMON'
    | 'SEARCH_ID_JOB_FULLTIME'
    | 'SEARCH_ID_BAP_SALE'
    | 'SEARCH_ID_COMMERCIAL_RENT'
    | 'SEARCH_ID_BOAT_USED_WANTED'
    | 'SEARCH_ID_BOAT_PARTS_MOTOR_WANTED'
    | 'SEARCH_ID_MC_COMMON'
    | 'SEARCH_ID_AGRICULTURE_THRESHING'
    | 'SEARCH_ID_REALESTATE_NEWBUILDINGS'
    | 'SEARCH_ID_ESTATE_PROJECT'
    | 'SEARCH_ID_BOAT_DOCK_WANTED'
    | 'SEARCH_ID_CAR_BUS'
    | 'SEARCH_ID_BAP_GROUP'
    | 'SEARCH_ID_COMMERCIAL_PLOTS'
    | 'SEARCH_ID_MC_SCOOTER'
    | 'SEARCH_ID_REALESTATE_PLANNEDPROJECT'
    | 'SEARCH_ID_TRAVEL_PREPACKAGE'
    | 'SEARCH_ID_TRAVEL_CURRENCY'
    | 'SEARCH_ID_COMMERCIAL_SALE'
    | 'SEARCH_ID_BAP_COMMON'
    | 'SEARCH_ID_JOB_PARTTIME'
    | 'SEARCH_ID_AGRICULTURE_TRACTOR'
    | 'SEARCH_ID_REALESTATE_LETTINGS_WANTED'
    | 'SEARCH_ID_TRAVEL_HOLIDAYRENTALS'
    | 'SEARCH_ID_CAR_CARAVAN'
    | 'SEARCH_ID_CAR_VAN'
    | 'SEARCH_ID_CAR_VAN_ABROAD'
    | 'SEARCH_ID_MOTOR_COMMON'
    | 'SEARCH_ID_REALESTATE_HOMES_BANNER'
    | 'SEARCH_ID_CAR_TRUCK_ABROAD'
    | 'SEARCH_ID_BOAT_DOCK'
    | 'SEARCH_ID_CAR_AGRI'
    | 'SEARCH_ID_REALESTATE_PLOTS'
    | 'SEARCH_ID_TRAVEL_LASTMINUTE'
    | 'SEARCH_ID_MC_USED'
    | 'SEARCH_ID_CAR_MOBILE_HOME'
    | 'SEARCH_ID_TRAVEL_FLIGHT'
    | 'SEARCH_ID_REALESTATE_LEISURE_SALE'
    | 'SEARCH_ID_MC_SNOWMOBILE'
    | 'SEARCH_ID_BOAT_USED'
    | 'SEARCH_ID_CAR_PARALLEL_IMPORT'
    | 'SEARCH_ID_REALESTATE_COMMON'
    | 'SEARCH_ID_CAR_TRUCK'
    | 'SEARCH_ID_REALESTATE_LEISURE_PLOTS'
    | null;
  searchFilters?: Record<string, string | string[]>;
  adId?: string | null;
}

export interface AdConfig {
  consent?: ConsentStatus;
  brand:
    | 'tori'
    | 'finn'
    | 'blocket'
    | 'dba'
    | 'bilbasen'
    | 'oikotie'
    | 'rakentaja';
  deviceType: DeviceType;
  vertical:
    | 'recommerce'
    | 'mobility'
    | 'jobs'
    | 'realestate'
    | 'travel'
    | 'front';
  subVertical:
    | 'industry'
    | 'cars'
    | 'boats'
    | 'motorcycles'
    | 'caravans'
    | 'residential-forsale'
    | 'residential-forrent'
    | 'leisure-forsale'
    | 'business-forsale'
    | 'business-forrent'
    | 'holiday-homes'
    | 'hotels'
    | 'flights'
    | 'prepackages'
    | null;
  pageType: 'front' | 'result' | 'object';
  adServer: AdConfigServer;
  placements: AdConfigPlacement[];
}

export type AdConfigPlacement = GamPlacement | AfsPlacement;

export interface GamPlacement {
  placementId: PlacementId;
  adServer: {
    type: 'GAM';
    config: GamPlacementConfig;
  };
  intermingle?: AdConfigIntermingle;
}

export interface AfsPlacement {
  placementId: PlacementId;
  adServer: {
    type: 'AFS';
    config: AfsPlacementConfig;
  };
  intermingle?: AdConfigIntermingle;
}

export interface GamConfig {
  networkId: string;
  adUnitName: string;
  targeting: GamKeyValue[];
}

export interface GamKeyValue {
  key: string;
  value: string[];
}

export interface AfsConfig {
  pubId: string;
  query: string;
  adPage: string;
}

export interface AdConfigIntermingle {
  grid: number | null;
  list: number | null;
}

export interface GamPlacementConfig {
  adUnitId: string;
  path: string;
  sizes: number[][];
  mediaTypes: SizeMediaType[];
  targeting: GamKeyValue[];
}

export interface AfsPlacementConfig {
  styleId: string;
}

export interface SizeMediaType {
  type?: 'native' | 'display' | 'video';
  width?: number;
  height?: number;
}

export enum DeviceType {
  Desktop = 'desktop',
  Mobile = 'mobile',
  Android = 'android',
  Ios = 'ios'
}

export enum ConsentStatus {
  Accepted = 'accepted',
  Rejected = 'rejected'
}

export enum AdServer {
  AFS = 'AFS',
  GAM = 'GAM',
  CONTENT_MARKETING = 'CONTENT_MARKETING'
}

export interface AdConfigServer {
  gam?: GamConfig;
  afs?: AfsConfig;
}

export enum PlacementId {
  Value = '',
  AdvtTop1 = 'advt_top_1',
  AdvtLeft1 = 'advt_left_1',
  AdvtLeft2 = 'advt_left_2',
  AdvtRight1 = 'advt_right_1',
  AdvtRight2 = 'advt_right_2',
  AdvtTop2 = 'advt_top_2',
  AdvtMiddle1 = 'advt_middle_1',
  AdvtMiddle2 = 'advt_middle_2',
  AdvtMiddle3 = 'advt_middle_3',
  AdvtMiddle4 = 'advt_middle_4',
  AdvtMiddle5 = 'advt_middle_5',
  AdvtBottom1 = 'advt_bottom_1',
  AdvtRecircMiddle1 = 'advt_recirc_middle_1',
  AdvtRecircMiddle2 = 'advt_recirc_middle_2',
  AdvtRecircMiddle3 = 'advt_recirc_middle_3',
  AdvtRecircMiddle4 = 'advt_recirc_middle_4',
  AdvtRecircMiddle5 = 'advt_recirc_middle_5',
  AdvtWallpaper = 'advt_wallpaper',
  AfsContainer1 = 'afs_container_1',
  AfsContainer2 = 'afs_container_2',
  AdvtFinancing1 = 'advt_financing_1',
  AdvtAudience = 'advt_audience',
  AdvtMarketing = 'advt_marketing',
  AdvtArticle1 = 'advt_article_1',
  AdvtArticle2 = 'advt_article_2',
  Topboard = 'topboard',
  Skyscraperright1 = 'skyscraperright_1',
  Skyscraperleft1 = 'skyscraperleft_1',
  Wallpaper = 'wallpaper',
  Board1 = 'board_1',
  Board2 = 'board_2',
  Board3 = 'board_3',
  Board4 = 'board_4',
  Board5 = 'board_5',
  Netboard1 = 'netboard_1',
  Netboard2 = 'netboard_2',
  Netboard3 = 'netboard_3',
  Netboard4 = 'netboard_4',
  Netboard5 = 'netboard_5',
  Contentboard = 'contentboard'
}

export type QueryParamsType = Record<string | number, unknown>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const array = query[key] as Array<unknown>;
    return array.map((value) => this.encodeQueryParam(key, value)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => 'undefined' !== typeof query[key]
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string'
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  protected createAbortSignal = (
    cancelToken: CancelToken
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {})
        },
        signal:
          (cancelToken
            ? this.createAbortSignal(cancelToken)
            : requestParams.signal) || null,
        body:
          typeof body === 'undefined' || body === null
            ? null
            : payloadFormatter(body)
      }
    ).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Advertising Ad Server Configuration Service API Documentation
 * @version latest
 *
 * Delivers configuration for the ad server and placements relative to site
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description Returns ad server and site location specific placement configuration and targeting
     *
     * @tags Config
     * @name V1ConfigCreate
     * @summary Returns ad server and site location specific placement configuration and targeting
     * @request POST:/api/v1/config
     */
    v1ConfigCreate: (data: ConfigRequest, params: RequestParams = {}) =>
      this.request<AdConfig, any>({
        path: `/api/v1/config`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    v2ConfigCreate: (data: ConfigRequest, params: RequestParams = {}) =>
      this.request<AdConfig, any>({
        path: `/api/v2/config`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  };
}
