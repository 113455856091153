import { $glimrTagsAtom, $ppidAtom } from '@client/core/atoms/config.js';
import { $gamTargetingAtom } from '@client/core/atoms/gamTargeting.js';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures.js';
import { getGlimrTags } from '@client/core/utils/glimr.js';
import {
  type AdConfigServer,
  debugLog,
  type GamKeyValue,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';

import { setTargetingOnSlotOrGlobal } from './targeting.js';

export function initiateGam(gamConfig: AdConfigServer['gam']) {
  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];

  // Push all configurations into a single command block -> or else signle request wont work
  window.googletag.cmd.push(() => {
    window.googletag.pubads().enableSingleRequest();
    window.googletag.pubads().collapseEmptyDivs(true);
    window.googletag.pubads().disableInitialLoad();
    window.googletag.pubads().setCentering(true);

    const enableGamLazyLoading = isFeatureEnabled(
      UNLEASH_FEATURE_NAME.enableGamLazyLoading
    );

    if (enableGamLazyLoading) {
      window.googletag.pubads().enableLazyLoad({
        fetchMarginPercent: 30,
        renderMarginPercent: 10
      });
    }

    $ppidAtom.subscribe((ppidData) => {
      const ppidString = ppidData?.delta?.ppId1 || ppidData?.delta?.ppId2;
      debugLog('Setting PPID on Gam', ppidString);
      if (ppidString) {
        window.googletag.pubads().setPublisherProvidedId(ppidString);
      }
    });

    $glimrTagsAtom.subscribe((glimrTags) => {
      const tags = glimrTags as string[];
      const glimrKeyValue = getGlimrTags(tags);
      debugLog('Setting GLIMR targeting on GAM', glimrKeyValue);
      setTargetingOnSlotOrGlobal({
        targeting: glimrKeyValue,
        global: true
      });
    });

    // Set global targeting
    $gamTargetingAtom.subscribe((updatedTargeting) => {
      const targeting = updatedTargeting as GamKeyValue[];
      setTargetingOnSlotOrGlobal({
        targeting,
        global: true
      });
    });

    // Enable services now that everything is set up
    window.googletag.enableServices();

    debugLog('Google Ad Manager initialized', window.googletag, gamConfig);
  });
}
