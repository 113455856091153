import type { WindowAdvt } from './state/index.js';
import type { GoogleTag } from './adServers/gam.js';
import type { AdnTag } from './adServers/adn.js';
import type { PlacementId } from './adPlacement.js';

declare global {
  namespace JSX {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface IntrinsicElements {
      /** @see https://github.schibsted.io/nmp-web/content-management-podlet */
      'advt-sponsored-content-teaser': {
        id: string;
        metaData?: {
          vertical: string;
          subvertical: string;
          pageType: string;
        };
        sponsoredContent?: object;
      };

      /** @see https://github.schibsted.io/nmp-web/content-management-podlet/blob/main/src/client/contentfulBlocks/containers/sponsored-content-cmp-wrapper.ts */
      'sponsored-content-cmp-wrapper': {
        /** @see https://github.schibsted.io/nmp-web/content-management-podlet/blob/main/src/types/types-cmp.ts */
        cmpContent: string;
        children?: any;
      };

      'advt-component': {
        containerId?: string | undefined;
        placementId: PlacementId;
        adIndex?: number | undefined;
        categories?: object | undefined;
        key?: string;
        initialLayoutType?: string | undefined;
        children?: any;
      };

      'contentful-sov-fetcher': {
        adPosition: PlacementId;
      };
    }
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    pulse: any;
    googletag: GoogleTag;
    adn: AdnTag;
    gtag(...args: any[]): void;
    dataLayer: Record<string, any>;
    adServer: Record<string, any>;
    apntag: Record<string, any>;

    _tcf_: {
      getConsentedToAllSync(): boolean;
      isConsentedToAll(callback: (value: boolean) => void): void;
    };

    ADVT: WindowAdvt;
    _googCsa(...args: any[]): void;

    /** Atom for creative to set static/sticky position on skyscrapers banners. */
    skyscrapersAtom: {
      get: () => 'sticky' | 'static';
      set: (value: 'sticky' | 'static') => void;
    };
  }
}

export {};
