import type { AdConfigIntermingle } from '../api/api.js';
import { PlacementId } from '../adPlacement.js';
import { AdServer } from '../api/adConfig.js';

export type LegacyAfsPlacement = {
  container: PlacementId;
  styleId: string;
};
export type LegacyAfsConfig = {
  pubId: string;
  query: string;
  adPage: string;
  placements: LegacyAfsPlacement[];
};
export type AfsConfig = {
  pubId: string;
  query: string;
  adPage: string;
};

export type AfsPlacementConfig = {
  styleId: string;
};

export type AfsPageOptions = {
  pubId: string;
  query: string;
  adPage: string;
  container: PlacementId;
  styleId: string;
  number: number;
};

export type AfsPlacement = {
  placementId: PlacementId;
  adServer: {
    type: AdServer.AFS;
    config: AfsPlacementConfig;
  };
  intermingle?: AdConfigIntermingle;
};

export type AfsQuery = {
  search: string;
  category: string;
  subCategory: string;
};
