import { debounce } from 'throttle-debounce';

import {
  debugLog,
  type Slot,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';
import { $waitForClient } from '@client/core/atoms/config.js';
import { addLifecycleEvent } from '@client/core/atoms/metrics.js';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures.js';
import * as RelevantDigital from '@client/core/relevantDigital/index.js';
import { debouncedSendMetrics } from '@client/core/services/metrics.js';

export namespace BatchedSlots {
  let queue = new Array<Slot>();

  export namespace Queue {
    export function add(slot: Slot) {
      queue.push(slot);
      flush.debounced();

      const waitForClient = $waitForClient.get();
      if (waitForClient) {
        $waitForClient.subscribe((wait) => {
          if (!wait) flush.debounced();
        });
      } else {
        flush.debounced();
      }
    }

    export function flush() {
      const slotsToLoad = [...queue];
      queue = [];
      load(slotsToLoad);
    }

    export namespace flush {
      /** Debounce function that accumulates slots and refreshes them after 200ms. */
      export const debounced = debounce(200, Queue.flush, { atBegin: false });
    }
  }

  export async function load(slots: Array<Slot>) {
    const deferredDisplay = Promise.withResolvers<void>();

    window.googletag.cmd.push(() => {
      for (const slot of slots) window.googletag.display(slot);
      deferredDisplay.resolve();
    });

    await deferredDisplay.promise;

    const formattedSlotIds = slots
      .map((slot) => slot.getSlotElementId())
      .join(', ');

    if (isFeatureEnabled(UNLEASH_FEATURE_NAME.enableGamPrebid)) {
      try {
        await RelevantDigital.Slots.load(slots);
        addLifecycleEvent(`Prebid auction completed for ${formattedSlotIds}`);
      } catch (error) {
        debugLog('Prebid auction failed', error);
        addLifecycleEvent(`Prebid auction failed for ${formattedSlotIds}`);
      }
    }

    const deferredRefresh = Promise.withResolvers<void>();

    window.googletag.cmd.push(() => {
      window.googletag.pubads().refresh(slots);
      deferredRefresh.resolve();
    });

    await deferredRefresh.promise;

    addLifecycleEvent(`GAM Load batch of Ads for ${formattedSlotIds}`);
    debouncedSendMetrics();
  }
}
