import { atom } from '@podium/store';
import type {
  AfsConfig,
  AfsPlacement
} from '@schibsted-nmp/advertising-shared';

import { AtomChannels, AtomTopics } from './index.js';

type AfsAtom = {
  placements: AfsPlacement[];
  config: AfsConfig;
};

export const $afsAtom = atom<AfsAtom>(AtomChannels.Podlet, AtomTopics.Afs, {
  placements: [],
  config: {} as AfsConfig
});
