import type { Features } from '../types/unleash.js';
import {
  UNLEASH_FEATURE_ARRAY,
  type UnleashFeatureName
} from './featureNames.js';

export class UnleashFeatureManager {
  features: Features;

  constructor(features: Features) {
    this.features = features;
  }

  isEnabled(featureName: string): boolean {
    if (!this.features || Object.keys(this.features).length === 0) return false;
    return this.features[featureName]?.enabled || false;
  }

  getAllFeatureStatuses(): { [K in UnleashFeatureName]: boolean } {
    return UNLEASH_FEATURE_ARRAY.reduce(
      (acc, featureName) => {
        acc[featureName] = this.features[featureName]?.enabled ?? false;
        return acc;
      },
      {} as { [K in UnleashFeatureName]: boolean }
    );
  }
}
