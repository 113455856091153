import { atom, type WritableAtom } from '@podium/store';
import type { AdnKeyValue } from '@schibsted-nmp/advertising-shared';

import { AtomChannels, AtomTopics } from './index.js';

export const $adnTargetingAtom: WritableAtom<Array<AdnKeyValue>> = atom<
  Array<AdnKeyValue>
>(AtomChannels.Podlet, AtomTopics.AdnTargeting, []);

/**
 * Updates the global targeting state with new key-value pairs for Adnuntius.
 *
 * The function merges the current targeting state with the new targeting values, ensuring:
 * - Keys are unique in the final state.
 * - If a key already exists, its values are merged with the new values.
 * - Duplicate values for the same key are removed.
 *
 * @param adnTargeting - An array of AdnKeyValue objects to add to the global targeting state.
 */
export const setAdnTargetingAtom = (adnTargeting: AdnKeyValue[]) => {
  const currentTargeting = $adnTargetingAtom.value ?? [];
  const newAdnTargeting = adnTargeting ?? [];

  // check if is array, if true return, else convert object into array
  const currentTargetingArray = Array.isArray(currentTargeting)
    ? currentTargeting
    : Object.entries(currentTargeting).flatMap(([key, values]) =>
        toArray(values).map((value) => ({ key, value }))
      );

  const newAdnTargetingArray = Array.isArray(newAdnTargeting)
    ? newAdnTargeting
    : Object.entries(newAdnTargeting).flatMap(([key, values]) =>
        toArray(values).map((value) => ({ key, value }))
      );

  const combinedTargeting = [...currentTargetingArray, ...newAdnTargetingArray];
  // Create a map to merge values with the same key
  const targetingMap = combinedTargeting.reduce<Map<string, string[]>>(
    (acc, item) => {
      for (const [key, values] of Object.entries(item)) {
        const existingValues = acc.get(key) || [];
        const newValues = Array.isArray(values) ? values : [values];

        // Merge and deduplicate values
        const mergedValues = Array.from(
          new Set([...existingValues, ...newValues])
        );

        acc.set(key, mergedValues);
      }
      return acc;
    },
    new Map()
  );

  // Convert the map back to an array of AdnKeyValue objects
  const uniqueKeysTargeting: AdnKeyValue[] = Array.from(
    targetingMap.entries()
  ).map(([key, values]) => ({ [key]: values }));

  $adnTargetingAtom.set(uniqueKeysTargeting);
};

const toArray = (value: AdnKeyValue | unknown): AdnKeyValue[] => {
  if (!value) return [];
  if (Array.isArray(value)) return value;

  // TODO: stop using unsafe type overrides:
  return [value as AdnKeyValue];
};
