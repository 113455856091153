import type { Config } from './Config.js';

export type Settings = Readonly<
  Partial<{
    /**
     * The Relevant Yield account ID used in the backoffice portal.
     * @example
     * 'schibsteddk' // extracted from 'https://schibsteddk-yield.relevant-digital.com/'
     */
    accountId: string;

    /**
     * The value is the name of the JS tag excluding its extension.
     * @example
     * '66cecb0e1a3b9040b52acd7c' // extracted from 'https://foo-cdn.relevant-digital.com/static/tags/66cecb0e1a3b9040b52acd7c.js'
     */
    tagName: string;

    /**
     * The Relevant Yield config ID for loading Prebid.
     * @example
     * relevantDigital.loadPrebid({ configId: '677e98761778e4c2a75d1be2' });
     */
    configId: string;
  }>
>;

export namespace Settings {
  /**
   * Recursively searches for a setting in the {@linkcode Config} object, based
   * on its key. It follows the path specified in prioritized order from left to
   * right, i.e. vertical → deviceType → consent, and return first most specific
   * match.
   * @example
   * const config = {
   *   accountId: 'foo',
   *   [DeviceType.DESKTOP]: { accountId: 'bar' },
   *   [Vertical.RECOMMERCE]: {
   *     accountId: 'baz',
   *     [DeviceType.TABLET]: {
   *       accountId: 'qux',
   *       tagName: 'quux',
   *     },
   *   },
   * };
   *
   * Settings.get('accountId', config, [Vertical.RECOMMERCE, DeviceType.MOBILE]); // → 'baz'
   * Settings.get('accountId', config, [Vertical.RECOMMERCE, DeviceType.DESKTOP]); // → 'baz'
   * Settings.get('accountId', config, [Vertical.RECOMMERCE, DeviceType.TABLET]); // → 'qux'
   * Settings.get('accountId', config, [Vertical.MOBILITY, DeviceType.MOBILE]); // → 'foo'
   * Settings.get('accountId', config, [Vertical.MOBILITY, DeviceType.DESKTOP]); // → 'bar'
   * Settings.get('tagName', config, [Vertical.RECOMMERCE, DeviceType.MOBILE]); // → null
   */
  export function get<
    TSettingsKey extends keyof Settings,
    TConfig extends Config.BrandSpecific,
    TPath extends ReadonlyArray<
      Exclude<keyof Config.BrandSpecific, keyof Settings>
    >
  >(
    settingsKey: TSettingsKey,
    config: TConfig,
    [currentPath, ...restPath]: TPath
  ): NonNullable<TConfig[TSettingsKey]> | null {
    do {
      try {
        if (!currentPath) break;

        const nextConfig = config[currentPath];
        if (!nextConfig) continue;

        const result = get(settingsKey, nextConfig, restPath);
        if (result) return result;
      } finally {
        [currentPath, ...restPath] = restPath;
      }
    } while (currentPath);

    return config[settingsKey] ?? null;
  }
}
