import React, { useState } from 'react';

import classNames from 'classnames';

import { useIsSmallScreen } from '@client/dev/components/utils/useIsSmallScreen.js';

export function DevPanelContainer({
  isOpen,
  children
}: {
  isOpen: boolean;
  children: React.ReactNode;
}) {
  const isSmallScreen = useIsSmallScreen();
  const [animate, setAnimate] = useState(true);

  const getStoredSize = (key: string, defaultValue: string) =>
    sessionStorage.getItem(key) ?? defaultValue;

  const width = getStoredSize('devPanelWidth', isSmallScreen ? '80%' : '425px');
  const height = getStoredSize(
    'devPanelHeight',
    isSmallScreen ? '100%' : '800px'
  );

  const handleMouseDown = () => {
    setAnimate(false);
  };

  const getDevPanelElement = () => {
    const shadowHost = document.querySelector('advt-dev-tool'); // Replace with actual selector
    if (shadowHost && shadowHost.shadowRoot) {
      return shadowHost.shadowRoot.querySelector('.dev-panel__container');
    }
    return null;
  };

  const onMouseUp = () => {
    setAnimate(true);
    const devPanelElem = getDevPanelElement();
    if (devPanelElem) {
      sessionStorage.setItem('devPanelWidth', `${devPanelElem.clientWidth}px`);
      sessionStorage.setItem(
        'devPanelHeight',
        `${devPanelElem.clientHeight}px`
      );
    }
  };

  return (
    <div
      className={classNames(
        'dev-panel__container shadow-s s-bg fixed top-0 left-0 flex flex-col p-2 z-30 max-h-screen min-h-28 min-w-96 p-0',
        {
          'transition-all duration-500 ease-in-out': animate,
          'max-w-screen-9/10': isSmallScreen,
          'max-w-screen': !isSmallScreen,
          'overflow-y-scroll overflow-y-auto': isOpen,
          'overflow-hidden': !isOpen
        }
      )}
      style={{
        maxHeight: '100dvh',
        resize: isOpen ? 'both' : 'none',
        width: isOpen ? width : 90,
        height: isOpen ? height : 30,
        overscrollBehavior: 'contain'
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={onMouseUp}
    >
      {children}
    </div>
  );
}
