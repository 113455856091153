import { debugLog, type InitialState } from '@schibsted-nmp/advertising-shared';

export const getInitialState = (): InitialState | null => {
  try {
    const jsonScript = document.getElementById('advertising-initial-state');
    if (jsonScript) {
      return JSON.parse(jsonScript.innerHTML);
    }
    return getNullWithLogs('Missing InitialState');
  } catch (error) {
    console.warn('Failed parsing initial state', error);
    return getNullWithLogs('Missing InitialState');
  }
};

export const hasInitialPlacements = (initialState: InitialState | null) =>
  initialState?.config?.placements?.length ??
  getNullWithLogs('No placements exists');

function getNullWithLogs(log: string) {
  debugLog(log);
  return null;
}
