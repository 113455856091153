import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import {
  AdServer,
  type ClientAdPlacement,
  debugLog,
  type GamPlacement,
  type LayoutType,
  PlacementId,
  type RecommendationCategories
} from '@schibsted-nmp/advertising-shared';
import { useInitiateGamUnit } from '@client/adManager/AdUnit/useInitiateGamUnit.js';
import { $config } from '@client/core/atoms/config.js';
import { $placementsMap } from '@client/core/atoms/placements.js';
import { observeFullWidthAd } from '@client/core/utils/dom/handleAdsOverlap/observeFullWidthAd.js';
import { setAdnamiPlacement } from '@client/core/utils/dom/handleStickySkyscrapers.js';
import type { InViewSettings } from '@client/core/utils/getInViewSettingsByPlacementId.js';

export type GamAdUnitProps = {
  placement: ClientAdPlacement<GamPlacement>;
  containerId: string;
  initialLayoutType?: LayoutType;
  categories?: RecommendationCategories;
  inViewSettings?: InViewSettings | undefined;
};
function GamAdUnit({ containerId, placement, inViewSettings }: GamAdUnitProps) {
  const { brand, deviceType, vertical, pageType } = $config.get();
  const { placementId } = placement;
  const ref = React.createRef<HTMLDivElement>();

  const [clientPlacement, setClientPlacement] =
    useState<ClientAdPlacement<GamPlacement>>(placement);
  const { creativeType } = clientPlacement;

  useEffect(() => {
    const unsubscribe = $placementsMap.subscribe((placements) => {
      const clientPlacement =
        typeof placements === 'object' && placements[placement.placementId];
      if (!clientPlacement) {
        return;
      }
      if (clientPlacement.adServer.type === AdServer.GAM) {
        setClientPlacement(clientPlacement as ClientAdPlacement<GamPlacement>);
      }
    });
    return () => unsubscribe();
  }, [placement]);

  const { isIntersecting } = useInitiateGamUnit({
    placement: clientPlacement,
    containerId,
    ref,
    inViewSettings
  });

  useEffect(() => {
    if (creativeType === 'adnami') {
      setAdnamiPlacement(placementId);
    } else if (
      (brand === 'tori' || brand === 'dba') &&
      placementId === PlacementId.Middle2 &&
      deviceType === 'desktop'
    ) {
      observeFullWidthAd(placementId, () => {
        debugLog('Full width ad detected');
        setAdnamiPlacement(placementId);
      });
    }
  }, [creativeType, placementId]);

  // Takeover ad styling
  const isTakeoverAd = creativeType === 'takeover';
  const takeoverIsIntersecting = isTakeoverAd && isIntersecting;

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const { body } = document;
      if (takeoverIsIntersecting) {
        body.classList.add('advt-takeover-ad');
      } else {
        body.classList.remove('advt-takeover-ad');
      }
    }
  }, [takeoverIsIntersecting]);

  // Sponsored Content & Native ad styling
  const isNativeAdStyles =
    creativeType === 'native' || creativeType === 'sponsored-content';
  const cardNativeAdVertical = ['realestate', 'travel'].includes(vertical);
  const isRecommendationFrontAd =
    isNativeAdStyles && placementId.includes('recirc') && pageType === 'front';
  const cardNativeAd =
    isNativeAdStyles && (cardNativeAdVertical || isRecommendationFrontAd);

  return (
    <div
      ref={ref}
      className={classNames('flex w-full advt-placement-container', {
        'is-intersecting': isIntersecting,
        'h-full': !isRecommendationFrontAd
      })}
    >
      <div
        className={classNames('mx-auto h-full advt-placement-item', {
          'w-full':
            creativeType === 'native' || creativeType === 'sponsored-content',
          'shadow-s hover:shadow-m border border-gray-200 rounded-8':
            cardNativeAd || creativeType === 'sponsored-content'
        })}
        id={placementId}
        data-is-adnami={creativeType === 'adnami'}
      />
    </div>
  );
}

export default GamAdUnit;
