import React from 'react';

import classNames from 'classnames';
import { css } from 'lit';

export function TogglePanelButton({
  isOpen,
  setIsOpen
}: TogglePanelButton.Props) {
  const onToggleClick = (e: any) => {
    e.preventDefault();
    setIsOpen((prevState) => !prevState);
  };

  const onCloseClick = (e: any) => {
    e.preventDefault();
    (window.ADVT as any).closeDevTool();
  };

  return (
    <div
      className={classNames(
        'toggle-panel-button__container absolute top-0 left-0 z-50 flex align-start',
        {
          'p-4 gap-4': isOpen,
          'p-0 gap-0': !isOpen
        }
      )}
    >
      <button
        className={classNames(
          'font-bold rounded-full text-xs h-20 overflow-hidden whitespace-nowrap',
          {
            'toggle-panel-button__close w-20': isOpen,
            'w-0': !isOpen
          }
        )}
        onClick={onCloseClick}
      >
        ✕
      </button>

      <button
        className={classNames('font-bold overflow-hidden whitespace-nowrap', {
          'toggle-panel-button__hide w-20 text-xs rounded-full h-20': isOpen,
          'toggle-panel-button__show s-bg-primary w-96 text-s rounded-0 h-32 s-text-inverted':
            !isOpen
        })}
        onClick={onToggleClick}
      >
        {isOpen ? '—' : 'Ad Debug'}
      </button>
    </div>
  );
}

export namespace TogglePanelButton {
  export type Props = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };

  export const styles = [
    css`
      .toggle-panel-button__close,
      .toggle-panel-button__hide {
        color: transparent;
        width: 14px;
        height: 14px;
        line-height: 14px;
        font-size: 8px;

        .toggle-panel-button__container:hover & {
          color: #000000b3;
        }
      }

      .toggle-panel-button__close {
        background-color: #fe5f57;
      }

      .toggle-panel-button__hide {
        background-color: #ffbc2c;
      }
    `
  ];
}
