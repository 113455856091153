import type { SearchKey, SearchParams } from '../layouts/search.js';
import type { DeviceType } from './adConfig.js';
import { ConsentStatus } from './api.js';

export type SearchConfigBody = {
  searchKey: SearchKey;
  searchParams: SearchParams;
  deviceType: DeviceType;
  consent: ConsentStatus;
  vertical: string;
  brand: string;
  subvertical: string;
};
