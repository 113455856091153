import { atom } from '@podium/store';
import { UnleashFeatureManager } from '@schibsted-nmp/advertising-shared';

import { AtomChannels, AtomTopics } from './index.js';

export const $unleashFeaturesAtom = atom(
  AtomChannels.Podlet,
  AtomTopics.UnleashFeatures,
  {}
);

let unleashFeatureManager: UnleashFeatureManager;

export function isFeatureEnabled(featureKey: string) {
  if (!unleashFeatureManager) {
    const features = $unleashFeaturesAtom.get();
    if (features) {
      unleashFeatureManager = new UnleashFeatureManager(features);
    } else {
      return false;
    }
  }
  return unleashFeatureManager.isEnabled(featureKey);
}

export function getAllFeatureStatuses() {
  return unleashFeatureManager.getAllFeatureStatuses();
}
