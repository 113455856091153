import type { AdnPlacement } from './types/adServers/adn.js';
import type { AfsPlacement } from './types/adServers/afs.js';
import type { GamPlacement } from './types/adServers/gam.js';
import { type AdConfigPlacement, AdServer } from './types/api/adConfig.js';

export function filterAfsPlacements(
  placements: AdConfigPlacement[]
): AfsPlacement[] {
  return Array.isArray(placements)
    ? (placements.filter(
        (placement) => placement.adServer.type === AdServer.AFS
      ) as AfsPlacement[])
    : [];
}

export function filterGamPlacements(
  placements: AdConfigPlacement[]
): GamPlacement[] {
  return Array.isArray(placements)
    ? (placements.filter(
        (placement) => placement.adServer.type === AdServer.GAM
      ) as GamPlacement[])
    : [];
}

export function filterAdnPlacements(
  placements: AdConfigPlacement[]
): AdnPlacement[] {
  return Array.isArray(placements)
    ? (placements.filter(
        (placement) => placement.adServer.type === AdServer.ADN
      ) as AdnPlacement[])
    : [];
}
