import React from 'react';

import { css, unsafeCSS } from 'lit';

import { lightGray } from '@client/dev/components/utils/colors.js';

import { Brackets } from './Brackets.js';

export function Static({ objectKey, brackets, ...props }: Static.Props) {
  const hasValue = 'value' in props;
  const type = hasValue ? typeof props.value : null;
  const [openingBracket, closingBracket] = brackets ?? [];

  return (
    <div>
      {Boolean(objectKey) && <span>{objectKey}: </span>}

      {openingBracket}

      {hasValue && (
        <span className={`object-explorer__value--${type}`}>
          {type === 'string' ? `'${props.value}'` : String(props.value)}
        </span>
      )}

      {closingBracket}
    </div>
  );
}

export namespace Static {
  export type Props = {
    objectKey: string | null;
    brackets?: Brackets;
  } & (
    | {
        value: unknown;
      }
    | {
        noValue: true;
      }
  );

  const __jsType = typeof (undefined as unknown);

  type JsType = typeof __jsType;

  const typeColors = {
    string: '#c57007',
    number: '#6c9220',
    bigint: '#299c2d',
    boolean: '#1259a4',
    undefined: lightGray,
    symbol: '#7b299c',
    function: '#938c01',
    object: lightGray // applies to null only
  } as const satisfies Record<JsType, string>;

  export const styles = [
    css`
      ${unsafeCSS(
        Object.entries(typeColors)
          .map(
            ([type, color]) => css`
              .object-explorer__value--${unsafeCSS(type)} {
                color: ${unsafeCSS(color)};
              }
            `.cssText
          )
          .join('\n')
      )}
    `
  ];
}
