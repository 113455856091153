import {
  type AdConfig,
  type AdConfigIntermingle,
  AdServer
} from '../api/adConfig.js';
import { PlacementId } from '../adPlacement.js';

export type AdnTag = {
  calls: Array<() => void>;
  requestData: AdnRequestData;
  setFeedbackOptions: (obj: { console: string; inScreen: string }) => void;
  request: (config: { adUnits: AdnAdUnit[] }) => void;
  util: {
    addEventListener: (
      object: object,
      eventName: string,
      handler: (event: any) => void
    ) => void;
  };
};

type AdnRequestData = (config: {
  adUnits: Array<AdnAdUnit>;
  onSuccess?: (data: {
    responseJSON: responseJSON;
    responseCode: number;
    responseText: string;
  }) => void;
  onError?: (data: {
    errorText: string;
    responseCode: number;
    responseText: string;
  }) => void;
  onResponse?: (data: {
    responseJSON?: responseJSON;
    responseCode: number;
    responseText: string;
  }) => void;
}) => void;

type responseJSON = {
  adUnits: Array<{
    matchedAdCount: number;
    targetId: string;
    ads: AdnAdUnit[];
  }>;
};
export type AdnAdUnit = {
  auId: string;
  targetId: string;
  kv: AdnKeyValue[];
  creativeWidth: string;
  creativeHeight: string;
};

export type AdnConfig = {
  kv: AdnKeyValue[];
};

export type AdnPlacementConfig = {
  auId: string;
  targetId: string;
  sizes: number[][];
};

export type AdnKeyValue = Record<string, string[]>;

export type AdnPlacement = {
  placementId: PlacementId;
  adServer: {
    type: AdServer.ADN;
    config: AdnPlacementConfig;
  };
  intermingle?: AdConfigIntermingle;
};

// atm config server is sending kv's as an object, we need them as an array
export const transformAdnKeyValuesToArray = (
  adConfig: AdConfig
): AdnKeyValue[] => {
  const adnKv = adConfig?.adServer?.adn?.kv || {};
  return (
    Object.entries(adnKv).map(([key, values]) => ({
      [key]: values as string[]
    })) || []
  );
};
