import React from 'react';

import { $config, $initialState } from '@client/core/atoms/config.js';
import { $consentAtom } from '@client/core/atoms/consent.js';
import { ConsentStatus } from '@schibsted-nmp/advertising-shared';
import { TcfApiMock } from '@client/TcfApiMock.js';
import { TcfHelperMock } from '@client/TcfHelperMock.js';

export const getUserId = () => {
  if (typeof window === 'undefined') return '';
  const metaTag = document.querySelector('meta[name="nmp:tracking:login-id"]');
  return metaTag?.getAttribute('content') || 'Not signed in';
};

export const ShortStatus = () => {
  const { env } = $initialState.get();

  const {
    pageType = '?',
    searchKey = '?',
    deviceType = '?',
    brand = '?',
    vertical = '?',
    subvertical = '?',
    adServer = {}
  } = $config.get() ?? {};

  const consent = $consentAtom.get();

  const configItems = [
    {
      label: 'Page type',
      value:
        pageType === 'result'
          ? `${pageType} ${searchKey?.replace('SEARCH_ID_', '')}`
          : pageType
    },
    { label: 'Device type', value: deviceType },
    { label: 'Brand', value: brand },
    { label: 'Vertical', value: `${vertical} ${subvertical}` },
    { label: 'User', value: getUserId() },
    { label: 'Consent', value: consent },
    {
      label: 'Ad servers',
      value: Object.keys(adServer)
        .map((key) => key.toUpperCase())
        .join(', ')
    }
  ];

  const toggleConsent = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      const nextConsentState =
        $consentAtom.get() === ConsentStatus.Rejected
          ? 'Consented'
          : 'NonConsented';

      TcfApiMock.TcData.set(nextConsentState);
      TcfHelperMock.ConsentState.set(nextConsentState);

      sessionStorage.setItem('ADVT_DEV_TOOL_consentState', nextConsentState);

      // TODO: Should be removed when we've implemented reacting to consent changes:
      window.location.reload();
    },
    []
  );

  return (
    <div
      className="grid gap-8 p-24"
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {configItems.map((item, index) => (
        <div
          key={index}
          className="flex flex-col gap-2 p-2"
          style={{
            width: '120px',
            textAlign: 'center'
          }}
        >
          <small className="text-xs text-gray-500">{item.label}</small>
          <b className="text-sm">{item.value}</b>
          {item.label === 'Consent' && env === 'local' && (
            <button
              className="bg-primary text-inverted rounded text-detail"
              onClick={toggleConsent}
            >
              toggle
            </button>
          )}
        </div>
      ))}
    </div>
  );
};
