import {
  type AdConfigServer,
  debugLog
} from '@schibsted-nmp/advertising-shared';

export function initiateAdn(adnConfig: AdConfigServer['adn']) {
  window.adn = window.adn || {};
  window.adn.calls = window.adn.calls || [];

  const eventNames = [
    'render',
    'request',
    'load',
    'impression',
    'click',
    'error',
    'complete',
    'visibilityChange'
  ];

  eventNames.forEach((eventName) => {
    window.adn.util.addEventListener(window, eventName, (event) => {
      debugLog(`Adn event: ${eventName}`, event);
    });
  });

  debugLog('Adn initialised:', adnConfig);
}
