import React from 'react';

import { css, unsafeCSS } from 'lit';
import classNames from 'classnames';

import { copyToClipboard } from '@client/dev/components/utils/copyToClipboard.js';

import { Brackets } from './Brackets.js';
import { Entry } from './Entry.js';

export function Expandable({
  objectKey,
  brackets,
  data,
  depth,
  showKeys,
  expanded = null
}: Expandable.Props) {
  const cantBeClosed = depth === 0;
  const [isOpen, setIsOpen] = React.useState(expanded || cantBeClosed);
  const childrenExpandedState = React.useState<boolean | null>(null);
  const [childrenExpanded, setChildrenExpanded] = childrenExpandedState;

  const toggleOpen = React.useCallback(() => {
    setIsOpen((value) => !value);
    setChildrenExpanded(null);
  }, [setIsOpen, setChildrenExpanded]);

  React.useEffect(() => {
    if (expanded !== null) setIsOpen(expanded);
  }, [expanded]);

  const [, closingBracket] = brackets;

  return (
    <div
      className={classNames(
        'object-explorer__expandable',
        `object-explorer__expandable--depth-${depth % Expandable.colors.length}`,
        { 'object-explorer__expandable--open': isOpen }
      )}
    >
      <Expandable.Actions
        data={data}
        childrenExpandedState={childrenExpandedState}
      />

      <Expandable.Toggle
        objectKey={objectKey}
        data={data}
        brackets={brackets}
        bracketClassNames={Brackets.className}
        toggleOpen={toggleOpen}
        cantBeClosed={cantBeClosed}
        isOpen={isOpen}
      />

      {isOpen && (
        <>
          <Expandable.Content
            data={data}
            depth={depth + 1}
            childrenExpanded={childrenExpanded}
            {...(showKeys && { showKeys })}
          />

          <div className={Brackets.className}>{closingBracket}</div>
        </>
      )}
    </div>
  );
}

export namespace Expandable {
  export type Props = {
    objectKey: string | null;
    brackets: Brackets;
    data: object;
    depth: number;
    expanded?: boolean | null;
    showKeys?: boolean;
  };

  export const colors = [
    '#88c15d',
    '#db498b',
    '#c2af00',
    '#02acac',
    '#5040ca'
  ] as const;

  export function Toggle({
    objectKey,
    data,
    brackets: [openingBracket, closingBracket],
    bracketClassNames,
    toggleOpen,
    cantBeClosed,
    isOpen
  }: Toggle.Props) {
    return (
      <div
        onClick={cantBeClosed ? undefined : toggleOpen}
        className={classNames('object-explorer__toggle', {
          'object-explorer__toggle--forced': cantBeClosed
        })}
      >
        {!cantBeClosed && <Expandable.Arrow isOpen={isOpen} />}

        <Toggle.Opener
          objectKey={objectKey}
          bracketClassNames={bracketClassNames}
          bracket={openingBracket}
        />

        <Toggle.Hint data={data} isOpen={isOpen} />

        {!isOpen && <span className={bracketClassNames}>{closingBracket}</span>}
      </div>
    );
  }

  export namespace Toggle {
    export type Props = {
      objectKey: string | null;
      brackets: Brackets;
      bracketClassNames: string;
      data: NonNullable<object> | NonNullable<Array<unknown>>;
      toggleOpen: () => void;
      cantBeClosed: boolean;
      isOpen: boolean;
    };

    export function Hint({ data, isOpen }: Hint.Props) {
      if (Array.isArray(data)) {
        return (
          <span className="object-explorer__items">
            {' '}
            {data.length} item{data.length > 1 && 's'} ...{' '}
          </span>
        );
      }

      if (isOpen) return null;

      return <span className="object-explorer__dots">{' ... '}</span>;
    }

    export namespace Hint {
      export type Props = {
        data: unknown;
        isOpen: boolean;
      };

      export const styles = [
        css`
          .object-explorer__items {
            color: var(--object-explorer__inactive-color);
            font-style: italic;
            font-size: 0.9em;
            font-family: var(--w-font-family);
          }

          .object-explorer__dots {
            color: var(--object-explorer__inactive-color);
          }
        `
      ];
    }

    export function Opener({
      bracketClassNames,
      objectKey,
      bracket
    }: Opener.Props) {
      if (!objectKey) {
        return <span className={bracketClassNames}>{bracket}</span>;
      }

      return (
        <span className="object-explorer__key">
          {objectKey}
          {': '}
          <Opener
            objectKey={null}
            bracketClassNames={bracketClassNames}
            bracket={bracket}
          />
        </span>
      );
    }

    export namespace Opener {
      export type Props = {
        objectKey: string | null;
        bracketClassNames: string;
        bracket: string;
      };
    }

    export const styles = [
      css`
        .object-explorer__toggle:not(.object-explorer__toggle--forced) {
          cursor: pointer;
        }
      `,
      ...Hint.styles
    ];
  }

  export function Content({
    data,
    showKeys = true,
    depth,
    childrenExpanded
  }: Content.Props) {
    const entries = React.useMemo(() => {
      const entries: Array<[string, unknown]> = Object.entries(data);
      if (!showKeys) return entries;

      return entries.sort(([a], [b]) => a.localeCompare(b));
    }, [data]);

    return (
      <div className="object-explorer__content">
        {entries.map(([key, value]) => (
          <Entry
            key={key}
            objectKey={showKeys ? key : null}
            data={value}
            depth={depth}
            expanded={childrenExpanded}
          />
        ))}
      </div>
    );
  }

  export namespace Content {
    export type Props = {
      data: object;
      showKeys?: boolean;
      depth: number;
      childrenExpanded: boolean | null;
    };

    export const styles = [
      css`
        .object-explorer__content {
          position: relative;
          padding-left: var(--object-explorer__indentation-width);
          border-left: 1px dotted var(--object-explorer__active-color);
        }
      `
    ];
  }

  export function Arrow({ isOpen }: Arrow.Props) {
    return <span className="object-explorer__arrow">{isOpen ? '▾' : '▸'}</span>;
  }

  export namespace Arrow {
    export type Props = { isOpen: boolean };

    export const styles = [
      css`
        .object-explorer__arrow {
          display: block;
          position: absolute;
          left: calc(0px - var(--object-explorer__indentation-width));
          width: var(--object-explorer__indentation-width);
          text-align: right;
          padding-right: 5px;
          color: var(--object-explorer__inactive-color);
        }
      `
    ];
  }

  export function Actions({ data, childrenExpandedState }: Actions.Props) {
    return (
      <div className="object-explorer__actions">
        <Actions.Button.ToggleChildrenExpanded
          childrenExpandedState={childrenExpandedState}
        />

        <Actions.Button.CopyJson data={data} />
      </div>
    );
  }

  export namespace Actions {
    export type Props = Button.CopyJson.Props &
      Button.ToggleChildrenExpanded.Props;

    export function Button({ title, onClick, children }: Button.Props) {
      return (
        <button
          title={title}
          className="object-explorer__actions-button"
          onClick={onClick}
        >
          {children}
        </button>
      );
    }

    export namespace Button {
      export type Props = {
        title: string;
        onClick: () => void;
        children: React.ReactNode;
      };

      export function CopyJson({ data }: CopyJson.Props) {
        const copyJson = React.useCallback(() => {
          copyToClipboard(JSON.stringify(data, null, 2));
        }, [data]);

        return (
          <Button title="Copy JSON" onClick={copyJson}>
            ⿻
          </Button>
        );
      }

      export namespace CopyJson {
        export type Props = { data: unknown };
      }

      export function ToggleChildrenExpanded({
        childrenExpandedState: [childrenExpanded, setChildrenExpanded]
      }: ToggleChildrenExpanded.Props) {
        const toggleChildrenExpanded = React.useCallback(() => {
          setChildrenExpanded((value) => !value);
        }, [setChildrenExpanded]);

        return (
          <Button
            title={`${childrenExpanded ? 'Collapse' : 'Expand'} children`}
            onClick={toggleChildrenExpanded}
          >
            {childrenExpanded ? '⇈' : '⇊'}
          </Button>
        );
      }

      export namespace ToggleChildrenExpanded {
        export type Props = {
          childrenExpandedState: [
            boolean | null,
            React.Dispatch<React.SetStateAction<boolean | null>>
          ];
        };
      }

      export const styles = [
        css`
          .object-explorer__actions-button {
            display: inline-flex;
            width: 24px;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            font-size: 14px;
            background-color: #fff;
            border: 1px solid #00000054;
            color: #000000;
            line-height: 1em;
            height: var(--object-explorer__actions-button-height);
            box-shadow:
              0px 1px 1px 0px #00000033,
              0 0 0px 2px #fff;

            &:hover {
              background-color: var(--w-s-color-background-selected);
            }

            & + & {
              margin-left: 4px;
            }
          }
        `
      ];
    }

    export const styles = [
      css`
        .object-explorer__actions {
          display: none;
          position: absolute;
          top: -24px;
          left: calc(0px - var(--object-explorer__actions-padding));
          padding: var(--object-explorer__actions-padding)
            var(--object-explorer__actions-padding) 0;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;
        }
      `,
      ...Button.styles
    ];
  }

  export const styles = [
    css`
      .object-explorer__expandable {
        --object-explorer__active-color--faded: hsl(
          from var(--object-explorer__active-color) h s l / 0.05
        );

        padding-right: 5px;
        position: relative;
        width: fit-content;

        &.object-explorer__expandable--open {
          & > .object-explorer__toggle {
            & > .object-explorer__key {
              border-bottom: 1px dotted var(--object-explorer__active-color);

              & > .object-explorer__bracket {
                color: var(--object-explorer__active-color);
              }
            }

            & > .object-explorer__arrow,
            & > .object-explorer__bracket {
              color: var(--object-explorer__active-color);
            }
          }

          & > .object-explorer__bracket {
            color: var(--object-explorer__active-color);
          }

          &:hover {
            & > .object-explorer__toggle > .object-explorer__key {
              border-bottom-style: solid;
              color: var(--object-explorer__active-color);
            }

            & > .object-explorer__content {
              border-left-style: solid;
            }
          }

          &:not(&:has(&:hover)):hover {
            background-color: var(--object-explorer__active-color--faded);
            backdrop-filter: opacity(0.9);
            box-shadow: 0px 0px 0px 1px
              var(--object-explorer__active-color--faded);

            & > .object-explorer__actions {
              display: inline-flex;
            }
          }
        }

        &:not(.object-explorer__expandable--open) {
          .object-explorer__toggle:hover {
            .object-explorer__bracket,
            .object-explorer__arrow,
            .object-explorer__key {
              color: var(--object-explorer__active-color);
            }
          }

          &:not(
              .object-explorer__expandable--open:has(
                  .object-explorer__expandable--open:hover
                )
            )
            .object-explorer__toggle:hover {
            background-color: var(--object-explorer__active-color--faded);
            backdrop-filter: opacity(0.9);
            box-shadow: 0px 0px 0px 1px
              var(--object-explorer__active-color--faded);
          }
        }
      }

      ${unsafeCSS(
        colors
          .map(
            (color, index) => css`
              .object-explorer__expandable--depth-${unsafeCSS(index)} {
                --object-explorer__active-color: ${unsafeCSS(color)};
              }
            `.cssText
          )
          .join('\n')
      )}
    `,
    ...Arrow.styles,
    ...Content.styles,
    ...Toggle.styles,
    ...Actions.styles
  ];
}
