import Glimr from 'glimr-sdk';

import localStorage from '@client/core/utils/localStorage.js';
import {
  type Brand,
  ConsentStatus,
  debugLog,
  type GamKeyValue
} from '@schibsted-nmp/advertising-shared';

import { $glimrTagsAtom } from '../atoms/config.js';
import { $consentAtom } from '../atoms/consent.js';

export const GLIMR_TAG_STORAGE_KEY = 'advertising-podlet.glimrTags';

function getClientIdByBrand(brand: Brand) {
  switch (brand) {
    case 'blocket':
      return 'AM3UZB2HBZHD9R7M55JS';
    case 'tori':
      return 'CQSKY6V5814SQJ61XFJQ';
    case 'finn':
      return 'ESA0AB9PATGZ2AGWICEX';
    case 'dba':
      return 'UYSCXA35QWYC4D4CWBC1';
    default:
      return '';
  }
}

export function clearPersistedGlimrTags() {
  localStorage.clearValue(GLIMR_TAG_STORAGE_KEY);
}

export function getPersistedGlimrTags() {
  return localStorage.getItem(GLIMR_TAG_STORAGE_KEY) || [];
}

export function setPersistedGlimrTags(tags: string[]) {
  $glimrTagsAtom.set(tags);
  localStorage.setItem(GLIMR_TAG_STORAGE_KEY, tags);
}

export async function loadGlimrIfPermission(brand: Brand) {
  const consent = $consentAtom.get();
  if (consent === ConsentStatus.Accepted) {
    const glimrTags = await loadGlimr({ brand });
    if (glimrTags?.tags) {
      $glimrTagsAtom.set(glimrTags.tags);
      setPersistedGlimrTags(glimrTags.tags);
    }
  } else {
    clearPersistedGlimrTags();
  }
}

export function getGlimrTags(tags: string[]): GamKeyValue[] {
  return [
    {
      key: 'glimr_weather',
      value: tags
    },
    {
      key: 'glimr_geo',
      value: tags
    }
  ];
}

export async function loadGlimr({ brand }: { brand: Brand }): Promise<{
  tags: string[];
  tagMappings: Record<string, unknown>;
}> {
  debugLog('Load glimr');

  return new Promise<{
    tags: Array<string>;
    tagMappings: Record<string, unknown>;
  }>((resolve) => {
    const clientId = getClientIdByBrand(brand);

    if (!clientId) {
      resolve({ tags: [], tagMappings: {} });
    } else {
      Glimr.setTagCacheTimeInSeconds(300);

      Glimr.getTags(clientId, (tags, tagMappings) =>
        resolve({ tags, tagMappings })
      );
    }
  });
}
