import { html } from 'lit';

import { AdVendor } from '@client/core/AdVendor.js';
import { PlacementId } from '@schibsted-nmp/advertising-shared';

export class EmptyAdVendor extends AdVendor {
  constructor(placementId: PlacementId) {
    super(null, placementId, `${placementId}--container`);
  }

  override setupEvents() {
    // do nothing
  }

  override cleanupEvents() {
    // do nothing
  }

  override requestAd() {
    // do nothing
  }

  override updated() {
    // do nothing
  }

  override render() {
    return html``;
  }
}
