import { html } from 'lit';

import { triggerAfsRequest } from '@client/adsense/index.js';
import { AdVendor } from '@client/core/AdVendor.js';
import { $config } from '@client/core/atoms/config.js';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures.js';
import {
  type AdConfigPlacement,
  type AdConfigServer,
  AdServer,
  type AfsPlacementConfig,
  type AfsQuery,
  PlacementId,
  UNLEASH_FEATURE_NAME,
  debugLog
} from '@schibsted-nmp/advertising-shared';

import { $afsAtom } from '../core/atoms/afs.js';

export class AfsAdVendor extends AdVendor {
  /**
   * This data is sent down as a param from the Podium Layout Server, and is used to
   * determine the query to be used for the AFS request. It will be replaced by server values
   * eventually.
   */
  afsQuery?: AfsQuery;

  constructor(placementId: PlacementId, afsQuery?: AfsQuery) {
    super(AdServer.AFS, placementId, placementId);
    if (afsQuery) this.afsQuery = afsQuery;
  }

  override updated() {
    // do nothing
  }

  override cleanupEvents() {
    // do nothing
  }

  override setupEvents() {
    this.cleanupEvents = $afsAtom.subscribe((afs) => {
      if (afs.config) {
        this.triggerAfsRequest({
          afsConfig: afs.config,
          placements: afs.placements
        });
      }
    });
  }

  private triggerAfsRequest({
    afsConfig,
    placements
  }: {
    afsConfig: AdConfigServer['afs'];
    placements: AdConfigPlacement[];
  }): void {
    if (!afsConfig) return;
    const afsPlacementConfig = placements.find(
      (placement) => placement.placementId === this.placementId
    )?.adServer.config as AfsPlacementConfig;
    const styleId = afsPlacementConfig?.styleId;
    if (!styleId) {
      debugLog(`No styleId found for container: ${this.placementId}`);
      return;
    }

    const overrideAfsPubId = isFeatureEnabled(
      UNLEASH_FEATURE_NAME.overrideAfsPubId
    );
    const overrideConfig = overrideAfsPubId
      ? {
          pubId: 'partner-pub-6408936348506194',
          styleId:
            $config.get().deviceType === 'desktop' ? '5268347338' : '3955265035'
        }
      : {};
    const afsPageOptions = {
      adPage: afsConfig.adPage,
      pubId: afsConfig.pubId,
      query: afsConfig.query,
      styleId,
      number: 1,
      ...overrideConfig
    };
    if (styleId) {
      triggerAfsRequest({
        container: this.placementId,
        ...afsPageOptions
      });
    }
  }

  override requestAd() {
    // AFS is triggered by the config event listener, do nothing here
  }

  override render() {
    return html`
      <slot
        name="${`${this.placementId}--slot`}"
        id="${this.placementId}"
      ></slot>
    `;
  }
}
