import { updateMetricByKey } from '@client/core/atoms/metrics.js';
import { updatePlacementKeyValueById } from '@client/core/atoms/placements.js';
import {
  type AdType,
  debugLog,
  type EventListenerCallbackEvent,
  type GamEventListenerEventName,
  PlacementId,
  PlacementStatusesEnum,
  type Slot
} from '@schibsted-nmp/advertising-shared';
import { isMiddleBannerOverlappingFilters } from '@client/core/utils/dom/handleAdsOverlap/isMiddleBannerOverlappingFilters.js';

import {
  eventIsNativeCpmAd,
  sizeIsAdnami,
  sizeIsFullScreenAd,
  sizeIsNativeAd,
  sizeIsDbaDesktopIntermingle,
  sizeExceedsCenterContentWidth
} from '../util.js';

export const setupGamEventListeners = (slot: Slot) => {
  const placementId = slot.getSlotElementId() as PlacementId;

  const events: GamEventListenerEventName[] = [
    // Fired when an ad has been requested for the ad slot.
    'slotRequested',
    // Fired when creative code has been injected into an ad slot.
    'slotRenderEnded',
    // Fired when an impression becomes viewable.
    'impressionViewable',
    // Fired when the on-screen percentage of the ad slot changes.
    'slotVisibilityChanged',
    // Fired when a creative iframe fires its onload event.
    'slotOnload'
  ];
  events.forEach((eventType) => {
    window.googletag.cmd.push(() => {
      window.googletag.pubads().addEventListener(eventType, (event) => {
        const eventSlot = event.slot;
        const eventElemId = eventSlot.getSlotElementId();

        if (eventElemId !== placementId) {
          // Skip events that are not related to the current slot
          return;
        }

        if (eventType !== 'slotVisibilityChanged') {
          debugLog(`PlacementId: ${placementId}: ${eventType} event:`, event);
        }

        if (eventType === 'slotRequested') {
          debugLog(
            `Google ad requested for placement: ${placementId} with slot`,
            eventSlot
          );
          updatePlacementKeyValueById(placementId, 'creativeType', 'banner');
          updatePlacementKeyValueById(placementId, 'status', 'requested');

          updateMetricByKey(placementId, PlacementStatusesEnum.AdRequested);
        }

        if (eventType === 'slotRenderEnded') {
          if (event?.isEmpty) {
            debugLog(
              `Google ad did not fill for placement: ${placementId} with slot`,
              eventSlot
            );
            updateMetricByKey(placementId, PlacementStatusesEnum.AdNoFill);
            updatePlacementKeyValueById(placementId, 'status', 'error');
            return;
          }
          const isMiddleSlot = slot
            .getSlotElementId()
            .startsWith('advt_middle_');

          if (
            isMiddleSlot &&
            shouldCollapseMiddleAd(event?.size, placementId)
          ) {
            debugLog(
              `Google ad overlaps with search filters: ${placementId} with slot`,
              eventSlot
            );
            updateMetricByKey(
              placementId,
              PlacementStatusesEnum.CollapsedOnCollision
            );
            updatePlacementKeyValueById(
              placementId,
              'status',
              'collapsedOnCollision'
            );
            return;
          }

          const creativeType: AdType = getCreativeType(event, isMiddleSlot);

          debugLog(`Creative type from size ${event.size}: ${creativeType}`);
          updatePlacementKeyValueById(
            placementId,
            'creativeType',
            creativeType
          );
          updatePlacementKeyValueById(placementId, 'status', 'loaded');
          updateMetricByKey(placementId, PlacementStatusesEnum.AdLoaded);
        }

        if (eventType === 'impressionViewable') {
          updateMetricByKey(placementId, PlacementStatusesEnum.Viewed);
        }
      });
    });
  });
};

function getCreativeType(
  event: EventListenerCallbackEvent,
  isMiddleSlot: boolean
): AdType {
  const { size } = event;
  // Check ad types in order of specificity
  if (isMiddleSlot && sizeIsDbaDesktopIntermingle(size)) return 'intermingles';
  if (sizeIsAdnami(size)) return 'adnami';
  if (sizeIsFullScreenAd(size)) return 'takeover';
  if (eventIsNativeCpmAd(event)) return 'sponsored-content';
  if (sizeIsNativeAd(size)) return 'native';
  return 'banner';
}

function shouldCollapseMiddleAd(
  adSize: number[],
  placementId: PlacementId
): boolean {
  return (
    sizeExceedsCenterContentWidth(adSize) &&
    isMiddleBannerOverlappingFilters(placementId)
  );
}
