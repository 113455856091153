import { debounce } from 'throttle-debounce';

import { updateMetricByKey } from '@client/core/atoms/metrics.js';
import { updatePlacementKeyValueById } from '@client/core/atoms/placements.js';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures.js';
import {
  type AfsPageOptions,
  debugLog,
  PlacementStatusesEnum,
  UNLEASH_FEATURE_NAME,
  PlacementId
} from '@schibsted-nmp/advertising-shared';

const REQUEST_DEBOUNCE_TIME = 200;

const debouncedAfsRequest = debounce(
  REQUEST_DEBOUNCE_TIME,
  (options: AfsPageOptions) => {
    const { container, query } = options;
    if (query && !isFeatureEnabled(UNLEASH_FEATURE_NAME.disableAfsPlacements)) {
      debugLog(`Triggering AFS request for container: ${container}`);
      updatePlacementKeyValueById(container, 'status', 'requested');
      updatePlacementKeyValueById(container, 'creativeType', 'banner');

      if (window.location.hostname.includes('local')) {
        updateMetricByKey(container, PlacementStatusesEnum.AdLoaded);
        updatePlacementKeyValueById(container, 'status', 'loaded');

        renderAFSPlaceholder(container);
      } else {
        try {
          window._googCsa('ads', options, {
            container,
            adLoadedCallback: (...args: Array<unknown>) => {
              updateMetricByKey(container, PlacementStatusesEnum.AdLoaded);
              updatePlacementKeyValueById(container, 'status', 'loaded');
              debugLog(`Arguments from adLoadedCallback: ${args}`);
            }
          });
          updateMetricByKey(container, PlacementStatusesEnum.AdRequested);
        } catch (error) {
          debugLog('Error in google ad sense [for search]: ', error);
        }
      }
    }
  },
  { atBegin: false }
);

export function triggerAfsRequest(options: AfsPageOptions) {
  debouncedAfsRequest(options);
}

function renderAFSPlaceholder(placementId: PlacementId) {
  setTimeout(() => {
    const slotElement = document.getElementById(placementId);

    if (slotElement) {
      const placeholder = document.createElement('div');
      Object.assign(placeholder.style, {
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        borderRadius: '10px',
        width: '100%',
        height: '396px',
        lineHeight: '396px',
        textAlign: 'center'
      });

      placeholder.textContent = 'Adsense placeholder';
      slotElement.innerHTML = '';
      slotElement.appendChild(placeholder);
    }
  }, 50);
}
