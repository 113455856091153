import type { EventListenerCallbackEvent } from '@schibsted-nmp/advertising-shared';

export function sizeIsNativeAd(size: number[]): boolean {
  return Array.isArray(size) && size[0] === 0 && size[1] === 0;
}

export function eventIsNativeCpmAd(event: EventListenerCallbackEvent): boolean {
  const nativeCpmCreativeTemplateIds = [12399765];
  return Boolean(
    event.creativeTemplateId &&
      nativeCpmCreativeTemplateIds.includes(event.creativeTemplateId)
  );
}

export function sizeIsFullScreenAd(size: number[]): boolean {
  return Array.isArray(size) && size[0] === 1920 && size[1] === 1080;
}

export function sizeIsAdnami(size: number[]): boolean {
  return Array.isArray(size) && size[0] === 1 && size[1] === 2;
}

export function sizeIsDbaDesktopIntermingle(size: number[]): boolean {
  return Array.isArray(size) && size[0] === 930 && size[1] === 180;
}

export function sizeExceedsCenterContentWidth(size: number[]): boolean {
  const CENTER_CONTENT_WIDTH_LIMIT = 620; // temp threshold, should be verified with design team
  return Array.isArray(size) && (size[0] ?? 0) > CENTER_CONTENT_WIDTH_LIMIT;
}
