import { setAdvtState } from './safeSetAdvtState.js';

export const ENABLED_DEBUG_PARAM = 'advt_debug';
export const ENABLED_DEBUG_PARAM_PLACEMENT_ID = 'advt_debug_placement';

let isDebugMode: boolean | undefined;
let debugPlacementFilter: string | null | undefined;

export function hasQueryParam(param: string) {
  return getQueryParam(param) !== null;
}

function getQueryParam(param: string) {
  return new URL(window.location.href).searchParams.get(param);
}

function initializeIsDebugMode() {
  const localStorageHasDebugFlag =
    window.localStorage.getItem(ENABLED_DEBUG_PARAM) === 'true';
  return localStorageHasDebugFlag || hasQueryParam(ENABLED_DEBUG_PARAM);
}

function initializePlacementIdDebugMode() {
  return (
    getQueryParam(ENABLED_DEBUG_PARAM_PLACEMENT_ID) ||
    window.localStorage.getItem(ENABLED_DEBUG_PARAM_PLACEMENT_ID)
  );
}

setAdvtState('toggleDebug', () => {
  const localStorageHasDebugFlag =
    window.localStorage.getItem(ENABLED_DEBUG_PARAM) === 'true';

  if (localStorageHasDebugFlag) {
    window.localStorage.removeItem(ENABLED_DEBUG_PARAM);
    window.localStorage.removeItem(ENABLED_DEBUG_PARAM_PLACEMENT_ID);
    debugPlacementFilter = null;
    return false;
  }
  window.localStorage.setItem(ENABLED_DEBUG_PARAM, 'true');
  return true;
});

function isDebug() {
  if (isDebugMode === undefined) {
    isDebugMode = initializeIsDebugMode();
  }

  if (isDebugMode && debugPlacementFilter === undefined) {
    debugPlacementFilter = initializePlacementIdDebugMode();
  }

  return isDebugMode;
}

const config = {
  name: 'advertising-podlet',
  primaryColor: 'skyblue',
  secondaryColor: 'royalblue'
};

export function debugLog(...args: any[]) {
  if (typeof window === 'undefined') return;
  if (!isDebug()) return;

  if (debugPlacementFilter) {
    const assertedDebugPlacementFilter = debugPlacementFilter;

    const containsPlacementId = args.some(
      (arg) =>
        typeof arg === 'string' && arg.includes(assertedDebugPlacementFilter)
    );

    if (!containsPlacementId) return; // Skip logging if no match
  }

  console.log(
    `%c ${config.name}`,
    `color:${config.primaryColor}; background:${config.secondaryColor}`,
    ...args
  );
}
