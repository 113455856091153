import {
  BrandEnum as Brand,
  ConsentStatus,
  DeviceTypeEnum as DeviceType,
  VerticalEnum as Vertical
} from '@schibsted-nmp/advertising-shared';
import { $config } from '@client/core/atoms/config.js';

import { Config } from './Config.js';
import type { Options } from './Options.js';

export namespace Helpers {
  /** Creates the parameters needed to grab Relevant Yield config IDs, script tag names, etc. */
  export function createParameters(options?: Options) {
    let { brand, deviceType, vertical, consent } =
      options ?? $config.get() ?? {};

    /*
      The config provider sometimes returns empty strings for some of these
      values so we need to provide defaults for them:
    */
    brand ||= Brand.TORI;
    deviceType ||= DeviceType.DESKTOP;
    vertical ||= Vertical.RECOMMERCE;
    consent ||= ConsentStatus.Rejected;

    const brandConfig = Config[brand];
    if (!brandConfig) return null;

    return {
      brandConfig,
      path: [vertical, deviceType, consent]
    } as const;
  }
}
