import { removeWallpaperDiv } from '@client/adManager/AdUnit/utils.js';
import { setAdnTargetingAtom } from '@client/core/atoms/adnTargeting.js';
import {
  getPlacementList,
  updatePlacementStatuses
} from '@client/core/atoms/placements.js';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures.js';
import fetcher from '@client/core/utils/api/fetcher.js';
import { getInitialState } from '@client/core/utils/dom/getInitialState.js';
import {
  type AdConfig,
  debugLog,
  filterAfsPlacements,
  type SearchConfigBody,
  type SearchKey,
  type SearchParams,
  UNLEASH_FEATURE_NAME,
  transformAdnKeyValuesToArray
} from '@schibsted-nmp/advertising-shared';

import { $afsAtom } from '../atoms/afs.js';
import { $config, $searchFilters } from '../atoms/config.js';
import { $consentAtom } from '../atoms/consent.js';
import { setGamTargetingAtom } from '../atoms/gamTargeting.js';
import { addLifecycleEvent } from '../atoms/metrics.js';
import { forceBatchRequestPlacements } from './getInViewSettingsByPlacementId.js';

export type FilterUpdatePayloadType = {
  searchKey?: SearchKey;
  searchParams: SearchParams;
};

function shouldUpdateKeywords(): boolean {
  // If the search params have changed, we need to update the keywords.
  // Here we can do more specific checks such as update on pagination but do not on sort.
  const currentSearchParams = $searchFilters.get().current || {};
  return $searchFilters.get().previous !== currentSearchParams;
}

export const refreshKeywords = async ({
  payload
}: Pick<
  import('@podium/browser').Event<FilterUpdatePayloadType>,
  'payload'
>) => {
  try {
    debugLog(`Received search filter update:`, payload);

    $searchFilters.set({
      previous: $searchFilters.get().previous,
      current: payload.searchParams
    });

    if (shouldUpdateKeywords()) {
      const { brand, deviceType, vertical, subvertical } = $config.get();
      const consent = $consentAtom.get();
      const body = {
        logSessionId: getInitialState()?.logSessionId || '',
        searchKey: payload.searchKey,
        searchParams: payload.searchParams,
        deviceType,
        consent,
        vertical,
        brand,
        subvertical
      } as SearchConfigBody;

      // Determine if the advertising config APIs are disabled
      const disableAdvertisingConfigApi = isFeatureEnabled(
        UNLEASH_FEATURE_NAME.disableAdvertisingConfigApi
      );

      // On page refresh, we need to remove the wallpaper div
      removeWallpaperDiv();

      // Split placements into two lists based on forceBatchRequestPlacements
      const allPlacements = getPlacementList();
      const [placementsToForceBatch, placementsToRefresh] =
        allPlacements.reduce(
          (acc, placement) => {
            if (forceBatchRequestPlacements.includes(placement.placementId)) {
              acc[0].push(placement);
            } else {
              acc[1].push(placement);
            }
            return acc;
          },
          [[], []] as [typeof allPlacements, typeof allPlacements]
        );

      // Create promises conditionally
      const searchFilterConfig = await (disableAdvertisingConfigApi
        ? Promise.resolve({
            data: null,
            error: ''
          })
        : fetcher<{
            data: AdConfig;
            error: string;
          }>('/api/update/targeting', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          }));

      addLifecycleEvent('Refresh targeting config after filter update');

      const filterData = searchFilterConfig?.data as AdConfig;

      $searchFilters.set({
        previous: payload.searchParams,
        current: $searchFilters.get().current
      });

      if (filterData?.adServer?.gam) {
        setGamTargetingAtom(filterData.adServer.gam.targeting);
      }
      if (filterData?.adServer?.adn) {
        setAdnTargetingAtom(transformAdnKeyValuesToArray(filterData));
      }

      // Update statuses for both groups
      updatePlacementStatuses(placementsToRefresh, 'refresh');
      updatePlacementStatuses(placementsToForceBatch, 'forceBatchRequest');

      if (filterData?.adServer?.afs) {
        const placements = filterAfsPlacements(filterData?.placements);
        const { afs } = filterData.adServer;
        debugLog(`Sending AFS config to AFS ad vendor`, {
          afs,
          placements
        });
        if (placements.length > 0) {
          $afsAtom.set({
            config: afs,
            placements
          });
        }
      }
    }
  } catch (error) {
    console.error(`Error in search-config API fetch: ${error}`);
  }
  return true;
};
