import { PlacementId } from '../adPlacement.js';
import { type AdConfigIntermingle, AdServer } from '../api/adConfig.js';

export type PubAdsService = {
  enableSingleRequest: () => void;
  disableInitialLoad: () => void;
  setCentering: (bool: boolean) => void;
  enableLazyLoad: (config?: {
    fetchMarginPercent?: number;
    renderMarginPercent?: number;
    mobileScaling?: number;
  }) => void;
  getSlots: () => Slot[];
  setPublisherProvidedId: (ppid: string) => void;
  collapseEmptyDivs: (collapse: boolean) => void;
  setTargeting: (key: string, value: string | string[]) => void;
  refresh: (slots?: Array<Slot>) => void;
  clear: (slots?: any[]) => void;
  addEventListener: (
    event: GamEventListenerEventName,
    callback: (event: EventListenerCallbackEvent) => void
  ) => void;
};

export type GamEventListenerEventName =
  | 'slotRequested'
  | 'slotRenderEnded'
  | 'impressionViewable'
  | 'slotVisibilityChanged'
  | 'slotOnload';

export type EventListenerCallbackEvent = {
  isEmpty: boolean;
  slot: Slot;
  size: number[];
  creativeTemplateId?: number;
};

export type SizeMappingBuilder = {
  addSize: (
    viewportSize: [number, number],
    slotSizes: [number, number][]
  ) => SizeMappingBuilder;
  build: () => any;
};

export type Slot = {
  addService: (service: PubAdsService) => Slot;
  addEventListener: (
    event: GamEventListenerEventName,
    callback: (event: EventListenerCallbackEvent) => void
  ) => void;
  getSlotElementId: () => string;
  getSizes: () => number[][];
  setTargeting: (key: string, value: string | string[]) => Slot;
  defineSizeMapping: (sizeMapping: any) => Slot;
};

export type GoogleTag = {
  cmd: any[];
  openConsole: () => void;
  pubads: () => PubAdsService;
  destroySlots: (slots: Slot[]) => void;
  defineSlot: (path: string, size: number[][], divId: string) => Slot;
  display: (divIdOrSlot: string | Slot) => void;
  enableServices: () => void;
  sizeMapping: () => SizeMappingBuilder;
  customQueue: any[];
};

export type GamConfig = {
  networkId: string;
  adUnitName: string;
  targeting: GamKeyValue[];
};

export type GamSize = number[] | string;
export type GamMediaType = 'display' | 'video' | 'native';
export type SizeMediaType = {
  type: GamMediaType;
  width: number;
  height: number;
};

export type SizeMapping = {
  sizes: [number, number][];
  breakpoint: [number, number];
};

export type GamPlacementConfig = {
  adUnitId: string;
  path: string;
  mediaTypes: SizeMediaType[];
  sizeMappings: SizeMapping[];
  targeting: GamKeyValue[];
};

export type GamKeyValue = {
  key: string;
  value: string | string[];
};

export type GamPlacement = {
  placementId: PlacementId;
  overrideAdServer?: AdServer;
  adServer: {
    type: AdServer.GAM;
    config: GamPlacementConfig;
  };
  intermingle?: AdConfigIntermingle;
};
